import { Component, OnInit,Output,EventEmitter, Input } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
 
@Component({
 selector: 'app-text-editor',
 templateUrl: './text-editor.component.html',
 styleUrls: ['./text-editor.component.css']
})
export class TextEditorComponent implements OnInit {
 
 public Editor = ClassicEditor;
 @Input() data:any;
 @Output() public valueChange = new EventEmitter();
 
 handleText(){
 this.valueChange.emit(this.data);
 }
 
 constructor() { }
 
 ngOnInit(): void {
 }
 
 
}